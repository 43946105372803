<template>
    <div class="home">
      <div class="pt20"></div>
     <el-form :inline="true" :model="formInline" class="demo-form-inline">
           
            <el-form-item style="font-weight: bold;" label="关键字">
            <el-input v-model="formInline.order_no" style="width:400px" placeholder="订单号"></el-input>
            <el-button  type="primary"  style="margin-left:20px" @click="onSubmit('CHA')">查询</el-button>
            </el-form-item>
            <!-- <el-button  type="info"  style="position: absolute;right: 45px;" @click="exportlist">导出</el-button> -->
      </el-form> 
      <el-table
        v-loading="loading"
        :data="tableData"
        style="width: 100%"
        :header-cell-style="{background:'#F5F7FA'}"
        @selection-change="handleSelectionChange"
         >
       <el-table-column type="selection" width="55" align="center"/>  
       <el-table-column
                  prop="order_no"
                  width='220'
                  label="订单编号">
                </el-table-column>
                <el-table-column
                  prop="child_status"
                  label="状态">
                <template  slot-scope="scope">
            {{scope.row.child_status ? '已处理' : ''}}    
            <font v-if="!scope.row.child_status" style="color:red;">未处理</font>
          </template>
                </el-table-column>
              <el-table-column
                  prop="created_at"
                  width='200'
                  label="发送时间">
                </el-table-column>
                <el-table-column
                  prop="is_call"
                  label="要求客服联系">
                <template  slot-scope="scope">
            {{scope.row.is_call == -1 ? '-' : scope.row.is_call ? '打通' : '未打通'}}    </template>
                </el-table-column>
                <el-table-column
                  prop="operator_name"
                  label="操作人">
                </el-table-column>
                <el-table-column
                  width='150'
                  label="操作">
                <template  slot-scope="scope">
                  <el-button @click='editaclog(scope.row)' size="mini" type="info" plain>编辑</el-button>
                </template>
                </el-table-column>
      </el-table>

      <div class="pt20"></div>
        <el-pagination
          @current-change="handleCurrentChange"
          :current-page.sync="current_page"
          :page-size="per_page"
          layout="total,prev, pager, next, jumper"
          :total="total">
        </el-pagination>
        
      <el-dialog
        title="编辑"
        :visible.sync="editactionlistshow"
        :fullscreen="false"
        :modal="false"
        width="80%"
        hight="80%"
        center>
        <div>
          <div>上传文件:
            <div v-for="(img,index) in ByOrderDataInfo.imgs" :key="index">
              <el-input type="text" v-model="names[index]" placeholder="请输入名称"></el-input>
              <el-image style="width: 100px; height: 100px" :src="img" :preview-src-list="ByOrderDataInfo.imgs"></el-image>
          </div>
          </div>
          <div class="pt20"></div>
          <div>备注信息:</div>
          <el-input
            type="textarea"
            :autosize="{ minRows: 2, maxRows: 10}"
            disabled
            placeholder="请输入内容"
            v-model="ByOrderDataInfo.remark">
          </el-input>
          <div class="pt20"></div>
          <div>不通过原因:</div>
          <el-input
            type="textarea"
            :autosize="{ minRows: 2, maxRows: 10}"
            placeholder="请输入内容"
            v-model="ByOrderDataInfo.message">
          </el-input>
          <div class="pt20"></div>
          <div>要求客服联系:</div>
          <el-radio v-model="ByOrderDataInfo.is_call" :label="1">打通</el-radio>
          <el-radio v-model="ByOrderDataInfo.is_call" :label="0">未打通</el-radio>
          <div class="pt20" style='text-align: center;'>
            <el-button @click="InfoExchange()" type="primary">保存</el-button>
          </div>
        </div>
      </el-dialog>
    </div>
</template>

<script>
// @ is an alias to /src   
import axios from 'axios'
import config from '../../lib/config'
// import formatDate from '../../lib/dateFormat'

export default {
  name: 'Home',
  data(){
    return {
      loading:true,
      total:0,
      per_page: 0,
      current_page:1,
      tableData: [],
      names:[],
      actionlogtypelist:[],
      actionlogtypelist2:[],
      actionlogtypelist3:[],
      actionlogtypelist4:[],
      payChannel:[],//支付渠道
      formInline: {
         order_no:'',//检索内容
      },
      status:'',//支付渠道
      timevalue:'',//交易日期
      // 选中数组
      ghs: [],
      nums:[],
      ByOrderDataInfo:{},
      logform:{
        actionlogtype1:'',
        actionlogtype2:'',
        actionlogtype3:'',
        actionlogtype4:'',
      },
     //选中的记录数量
     initdata:{
         order_no:'',//检索内容
     },
     selectedNum:0,
      editactionlistshow:false,
     pickerOptions: {
          shortcuts: [{
            text: '最近一周',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [start, end]);
            }
          }, {  
            text: '最近一个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit('pick', [start, end]);
            }
          }]
        },
    }
  },
  filters: {
    formatDate2(timestamp) {
      const date = new Date(timestamp*1000);
      const year = date.getFullYear();
      const month = ('0' + (date.getMonth() + 1)).slice(-2);
      const day = ('0' + date.getDate()).slice(-2);
      const hour = ('0' + date.getHours()).slice(-2);
      const minute = ('0' + date.getMinutes()).slice(-2);
      const second = ('0' + date.getSeconds()).slice(-2);
      return `${year}-${month}-${day} ${hour}:${minute}:${second}`;
    }
  },
  methods: {
      exportlist(){

      },
      editsubmitactionlog() {
        let _this = this;
        console.log(this.logform)
        if (!this.logform.actionlogtype1 || !this.logform.remark) {
          _this.$message.error('表单数据不能为空');
          return false;
        }
        this.logform.order_no = _this.topOrder_no;
        console.log(_this.logform.actionlogtype4.toString())
        let data = {
          id:_this.logform.id,
          action:_this.logform.actionlogtype1 ? _this.logform.actionlogtype1 : 0,
          specific_situation:_this.logform.actionlogtype2 ? _this.logform.actionlogtype2 : 0,
          specific_doubts:_this.logform.actionlogtype3 ? _this.logform.actionlogtype3 : 0,
          specific_materials:_this.logform.actionlogtype4 ? _this.logform.actionlogtype4.toString() : '',
          remark:_this.logform.remark ? _this.logform.remark : '',
        };
        this.$refs.logform.validate(valid => {
          if (valid) {
            axios.post(config.action_log,data)
            .then(function (response) {
                if(response.data.code == 200){
                  _this.$message({
                    message: response.data.message,
                    type: 'success'
                  });
                  _this.handleSelect('10');
                  _this.editactionlistshow = false;
                }else{
                  _this.$message.error(response.data.message);
                }
              })
            .catch(function (error) {
              console.log(error);
            });
          } else {
            _this.$message.error('表单验证失败');
            return false;
          }
        });
      },
      actionlogtypeson(pid,type,boo){
        let _this = this;
        axios.get(config.selectaction_log_type,{params:{pid:pid}})
        .then(function (response) {
              if(response.data.code == 200){
                if(type == 1){
                  _this.actionlogtypelist2 = response.data.data;
                  if(!boo){
                    _this.actionlogtypelist3 = [];
                    _this.actionlogtypelist4 = [];
                    _this.logform.actionlogtype2 = '';
                    _this.logform.actionlogtype3 = '';
                    _this.logform.actionlogtype4 = '';
                  }
                }  
                if(type == 2){
                  _this.actionlogtypelist3 = response.data.data;
                  if(!boo){
                    _this.actionlogtypelist4 = [];
                    _this.logform.actionlogtype3 = '';
                    _this.logform.actionlogtype4 = '';
                  }
                }
                if(type == 3){
                  _this.actionlogtypelist4 = response.data.data;
                  if(!boo){
                    _this.logform.actionlogtype4 = '';
                  }
                }
              }else{
                _this.$message.error(response.data.message);
              }
          })
        .catch(function (error) {
          _this.$message.error(error);
        });
      },
      InfoExchange(){
        let _this = this;
        if(_this.ByOrderDataInfo.is_call === ''){
          _this.$message.error('请选择是否打通！');
          return;
        }
        let name = [];
        for(let i in _this.names){
          name.push({
            name:_this.names[i]
          })
        }
        let data = {
          id:_this.ByOrderDataInfo.id,
          image:name,
          message:_this.ByOrderDataInfo.message?_this.ByOrderDataInfo.message:' ',
          is_call:_this.ByOrderDataInfo.is_call,
        }
        axios.post(config.exchange,data)
        .then(function (response) {
            if(response.data.code == 200){
              _this.$message({
                type: 'success',
                message: '保存成功!'
              });
              _this.onSubmit('CHA');
              _this.editactionlistshow = false;
            }else{
              _this.$message.error(response.data.message);
            }
          })
        .catch(function (error) {
          console.log(error);
        });
      },
    editaclog(data){
      let _this = this;
      _this.editactionlistshow = true;
      _this.names = [];
      axios.get(config.exchange_detail,{params:{id:data.id}})//用户联系详情  
        .then(function (response) {
            if(response.data.code == 200){
              _this.ByOrderDataInfo = response.data.data;
              if(_this.ByOrderDataInfo.images != ''){
                _this.ByOrderDataInfo.imgs = _this.ByOrderDataInfo.images.split(',');
              }
              if(_this.ByOrderDataInfo.name != ''){
                _this.names = _this.ByOrderDataInfo.name.split(',');
              }
            }else{
              _this.$message.error(response.data.message);
            }
          })
        .catch(function (error) {
          _this.$message.error(error);
        });
    },
      onSubmit(CHA){
        let _this = this;
        _this.loading = true;
        if(CHA == 'CHA'){
          this.formInline.page = 1;
        }
        axios.get(config.exchange_all,{params:_this.formInline})
        .then(function (response) {
            if(response.data.code == 200){
              _this.tableData = response.data.data.data
              _this.setpage(response.data.data)
            }else{
              _this.$message.error(response.data.message);
            }
              _this.loading = false
          })
        .catch(function (error) {
          _this.$message.error(error);
        });
      },
      handleSelect(key) {
        this.formInline.order_type = key;
        this.onSubmit('CHA');
      },
      setpage(page){
        this.total = page.total;
        this.per_page = page.per_page;
        this.current_page = page.current_page;
      },typeIndex(index) {
        let _this = this; //处理分页数据的 index
        return (_this.formInline.page - 1) * 20 + index + 1;
      },
      handleCurrentChange(val) {
        this.formInline.page = val;
        this.onSubmit();
        document.getElementsByClassName('el-main')[0].scrollTop = 0;
      },
    // 多选框选中数据
    handleSelectionChange(selection) {
        //获取所有选中项的gh(工号)属性的值
        this.ghs = selection.map(item => item.id)
        this.nums = selection.map(item => item.num)
        //获取所有选中项数组的长度
        this.selectedNum = selection.length
      },
    init(){
        let _this = this;
        axios.get(config.exchange_all,{params:this.initdata})
        .then(function (response) {
            if(response.data.code == 200){
              _this.tableData = response.data.data.data
              _this.setpage(response.data.data)
            }else{
              _this.$message.error(response.data.message);
            }
              _this.loading = false
          })
        .catch(function (error) {
          _this.$message.error(error);
        });
        
        axios.get(config.selectaction_log_type,{params:{pid:0}})
        .then(function (response) {
              if(response.data.code == 200){
               _this.actionlogtypelist = response.data.data;
              }else{
                _this.$message.error(response.data.message);
              }
          })
        .catch(function (error) {
          _this.$message.error(error);
        });
    }
  },
  created(){
      this.init();
  }
}
</script>
<style scoped>
.left{
    width: 70px;
    text-align: left;
}
</style>